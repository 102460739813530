<template>
    <div class="row">
        <div class="mt-0 d-block sub-nav-alt-item">
            <div class="call-action-banner pt-30 pb-30 position-re radius-20" 
                style="background-image: url(/assets/img/switch365/darkBlueBackLightBluePinkSpots.webp); 
                background-size: cover; border: 2px solid white" data-overlay-dark="0">
                
                <div class="row position-re ontop">

                        <NavbarContactForm :formHeading="processedHeader"  />



                        <!-- <div class="">
                            <h5 class="form-heading mb-10"> {{ processedHeader }}</h5>

                            <p v-if="state.form.businessName" class="input-label">Business Name</p>
                            <input class="form-control mb-2" v-model="state.form.businessName" :class="state.error.businessName === true ? 'errorInput' : ''" id="form_businessName" type="text" name="businessName" placeholder="Business Name" required="required" />

                            <p v-if="state.form.contactName" class="input-label">Contact Name</p>
                            <input class="form-control mb-2" v-model="state.form.contactName" :class="state.error.contactName === true ? 'errorInput' : ''" id="form_contactName" type="text" name="contactName" placeholder="Contact Name" required="required" />

                            <p v-if="state.form.email" class="input-label">Email</p>
                            <input v-model="state.form.email" :class="state.error.email === true ? 'errorInput' : ''" class="form-control mb-2" type="email" name="email" placeholder="Email" required="required" />
                            
                            <p v-if="state.form.phoneNumber" class="input-label">Phone Number</p>
                            <input v-model="state.form.phoneNumber" class="form-control mb-2" name="phoneNumber" placeholder="Phone Number" />
                            
                            <p v-if="state.form.message" class="input-label">Message</p>
                            <textarea v-model="state.form.message" :class="state.error.message === true ? 'errorInput' : ''" class="form-control mb-2" name="message" placeholder="Message" rows="4" required="required"></textarea>
                            <input ref="quoteDataInput" id="quote_form_email" hidden />

                            <div style="text-align: start; margin-top: 20px; margin-bottom: 15px;">
                                <p v-if="state.error.errMessage" 
                                    class="errorInput"
                                    style="color:white; background-color: red; padding: 0 10px; border-radius: 7px; display: inline-block;">
                                        {{ state.error.errMessage  }}
                                </p>
                            </div>

                            <button @click="submit()" class="p-1 pinkyBtn float-end mx-a" style="font-weight: 600">Send Message</button>


                        </div>  -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import NavbarContactForm from './navbarContactForm.vue';

// Define props
const props = defineProps({
  // Example prop definitions
  formHeading: String
});

const processedHeader = ref("Submit for a Callback!")


const updateHeaderBasedOnScreenSize = () => {
  try {
    const isMobile = window.innerWidth < 768;
    processedHeader.value = isMobile ? props.formHeading.split('–')[1].trim() : props.formHeading;
  } catch (e) {
    processedHeader.value = props.formHeading;
  }
};

onMounted(() => {
  updateHeaderBasedOnScreenSize(); // Call it on mount to set initial value
  window.addEventListener('resize', updateHeaderBasedOnScreenSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateHeaderBasedOnScreenSize);
});
const quoteDataInput = ref(null)

let axInstanct = axios.create({
    headers: {
        'X-API-KEY': 'px51zFHJx4w46fI05Uy94Rv56wlEXp',
    },
})

function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

// function validateUKPhone(phoneNumber) {
//   const re = /^(\+44|0)7\d{9}$/;
//   if (!re.test(phoneNumber)) {
//     return false;
//   } else {
//     return true;
//   }
// }


function validateUKPhone(phoneNumber) {
  const re = /^\+?(?:\d{1,3})?[-\s.]?(?:\(\d{1,3}\)|\d{1,3})[-\s.]?\d{1,4}[-\s.]?\d{1,4}[-\s.]?\d{1,9}$/;
  return re.test(phoneNumber);
}

const state = reactive({
    form: {
        businessName: '',
        contactName: '',
        email: null,
        subject: null,
        message: null,
        phoneNumber: null,
        quoteData: null,
    },
    error: {
        businessName: false,
        contactName: false,
        email: false,
        phoneNumber: false,
        subject: false,
        message: false,
        errMessage: null

    },
})

async function submit() {


    try{ 

    state.error.errMessage = null;

    if (quoteDataInput.value.value) {
        state.form.quoteData = quoteDataInput.value.value
    }

    if (!state.form.businessName || !state.form.contactName || !state.form.email || !state.form.phoneNumber) {
        Toastify({
            text: 'Please complete all fields',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'bottom', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                background: 'linear-gradient(to right, #432677, #26BEAF)',
            },
        }).showToast()

        state.error.businessName = false
        state.error.contactName = false
        state.error.email = true
        state.error.phoneNumber = false
        state.error.errMessage = 'Please complete all fields'

        return;
    } 

    
    if ( !validateUKPhone(state.form.phoneNumber) ) {

        Toastify({
            text: 'Please enter a valid number',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'bottom', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                background: 'linear-gradient(to right, #432677, #26BEAF)',
            },
        }).showToast()
        
        state.error.businessName = false
        state.error.contactName = false
        state.error.email = false
        state.error.phoneNumber = true
        state.error.errMessage = 'Please enter a valid number'

        return; 
    }
    

    if ( !validateEmail(state.form.email) ) {

        Toastify({
            text: 'Please enter a valid email',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'bottom', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                background: 'linear-gradient(to right, #432677, #26BEAF)',
            },
        }).showToast()

        state.error.businessName = false
        state.error.contactName = false
        state.error.email = true
        state.error.phoneNumber = false
        state.error.errMessage = 'Please enter a valid email'

        return; 
    }

    
    if (!state.form.message) {

        Toastify({
            text: 'Please include a message',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'bottom', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                background: 'linear-gradient(to right, #432677, #26BEAF)',
            },
        }).showToast()

        state.error.businessName = false
        state.error.contactName = false
        state.error.email = false
        state.error.subject = false
        state.error.message = true

        state.error.errMessage = 'Please include a message'
        return; 
    } 
    
   
    state.error = {
        businessName: false,
        contactName: false,
        email: false,
        subject: false,
        message: false,
    }
    const response = await axInstanct.post('https://api.switch365.com/web/Send_Contact', state.form)
        .then(() => {
            Toastify({
                text: 'Thank you, we will be in touch shortly',
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'bottom', // `top` or `bottom`
                position: 'right', // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: 'linear-gradient(to right, #432677, #26BEAF)',
                },
            }).showToast()

            quotemeform.hide()

            state.form = {
                businessName: '',
                contactName: '',
                email: null,
                subject: null,
                message: null,
                quoteData: null,
            }

        })

    } catch (error) {
        console.log('error---')
        console.log(error)

    }

        // state.form.businessName =  ''
        // state.form.contactName = ''
        // state.form.email = null
        // state.form.subject = null
        // state.form.message = null
        // state.form.phoneNumber = null
        // state.form.quoteData = null
        
    }

</script>

<style>

.input-label {
    font-size: 14px;
    /* font-weight: 600; */
    margin-top: 10px;
    margin-bottom: 5px;

    color: #fff !important; 
}

.form-heading {
    font-size: 25px; /* Default font size */
}

.errorInput {
    font-size: 17px;
}

@media (max-width: 768px) {
    .form-heading {
        font-size: 15px; 
        text-align: center;
    }
    .errorInput {
        font-size: 13px;
    }
}

</style>
